<template>
	<b-tabs content-class="mt-3" justified>
		<b-tab :title="$t('VIEWS.Dashboard.Mail.Text.text')" active>
			<div class="form-group mb-1">
				<label>{{ $t('VIEWS.Dashboard.Mail.Text.text') }} <span class="text-danger">*</span></label>
				<b-select v-model="whatsapp.template" :options="this.templates" value-field="id" text-field="de"></b-select>
			</div>
		</b-tab>
		<b-tab :title="$t('VIEWS.Dashboard.Mail.Address.addressList')">
			<div class="row">
				<div class="col-12 col-md-6 border-right">
					<div class="form-group validated">
						<label>{{ $t('VIEWS.Dashboard.Share.addNumber') }}</label>
						<div class="input-group">
							<b-input-group>
								<template #append>
									<b-input-group-text v-on:click.prevent="addNumber" class="cursor-pointer">
										<i class="fas fa-plus"></i>
									</b-input-group-text>
								</template>
								<b-input type="text" class="form-control" v-model="$v.number.$model" :state="$v.number.$error === true ? false : null" ></b-input>
							</b-input-group>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="form-group">
						<label>{{ $t('VIEWS.Dashboard.Mail.Address.uploadFile') }}</label>
						<div></div>
						<div class="custom-file">
							<input type="file" class="custom-file-input" accept=".txt,.csv" ref="uploadedFile" v-on:change="fileChange" />
							<label class="custom-file-label">{{ $t('VIEWS.Dashboard.Mail.Address.uploadSelect') }}</label>
							<span class="form-text text-muted">{{ $t('VIEWS.Dashboard.Mail.Address.uploadInfo') }}</span>
						</div>
					</div>
				</div>
				<div class="col-12">
					<hr />
				</div>
				<div class="col-12 col-md-6 border-right">
					<h6>{{ $t('VIEWS.Dashboard.Share.listOfNumbers') }}</h6>
					<div class="prinor-table">
						<b-table :data="whatsapp.numbers" :paginated="true" :per-page="5">
							<b-table-column field="mail" :label="$t('VIEWS.Dashboard.Share.phone')" v-slot="props" :sortable="true" :searchable="true">
								<template>
									<span class="truncate">{{ props.row }}</span>
								</template>
							</b-table-column>
							<b-table-column :label="$t('GENERAL.Forms.delete')" v-slot="props" :sortable="false" :searchable="false">
								<template>
									<button class="btn btn-outline-danger" @click.prevent="deleteNumber(props.row)">{{ $t('GENERAL.Forms.delete') }}</button>
								</template>
							</b-table-column>
						</b-table>
					</div>
				</div>
				<div class="col-12 col-md-6 border-right">
					<h6>{{ $t('GENERAL.General.importantInformation') }}</h6>
					<p>{{ $t('VIEWS.Dashboard.Share.info') }}</p>
				</div>
			</div>
			<modal name="csv-config">
				<div class="card card-custom">
					<div class="card-header card-header-tabs-line">
						<div class="card-title">
							<h3 class="card-label">{{ $t('VIEWS.Dashboard.Mail.CSV.title') }}</h3>
						</div>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label for="columnSelect">{{ $t('VIEWS.Dashboard.Mail.CSV.column') }}</label>
									<select class="form-control" id="columnSelect" v-model="columnSelect">
										<option :value="index" v-for="(column, index) in columnSelectData" :key="'s' + column + index">{{ column }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer d-flex justify-content-between">
						<button type="button" v-on:click.prevent="hideModal" class="btn btn-outline-danger">{{ $t('GENERAL.Buttons.cancel') }}</button>
						<button type="button" v-on:click.prevent="addNumbersCsv" class="btn btn-success">{{ $t('GENERAL.Forms.save') }}</button>
					</div>
				</div>
			</modal>
		</b-tab>
	</b-tabs>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { PRINOR_TOASTS } from "@/core/services/toast.service";
import ApiService from "@/core/services/api.service";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import Swal from "sweetalert2";
import parsePhoneNumber from 'libphonenumber-js'

const phoneRegEx = new RegExp("^\\+\\d+|^\\d+$");
const phoneNumber = value => phoneRegEx.test(value);

export default {
	name: 'ShareWhatsapp',
	props: {
		survey: Object,
	},
	data: function() {
		return {
			whatsapp: {
				template: '',
				numbers: [],
			},
			number: '',
			columnSelect: -1,
			columnSelectData: [],
			templates: [],
		};
	},
	mixins: [validationMixin],
	validations: {
		whatsapp: {
			template: {
				required,
			},
			numbers: {
				required,
				minLength: minLength(1),
			},
		},
		number: {
			phoneNumber,
		},
	},
	computed: {
		surveyUrl: function() {
			return process.env.VUE_APP_URL_SURVEY;
		},
	},
	mounted() {
		this.getTemplates().then(data => {
			this.templates = data.data;
		});
	},
	methods: {
		getTemplates: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('survey/share/whatsapp/' + this.survey.id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === false) {
							PRINOR_TOASTS.general.error(this, data.data.error);
						} else {
							resolve(data.data);
						}
					}
				});
			});
		},
		addNumber: function() {
			this.$v.$touch();
			if (!this.$v.number.$invalid) {
				if (!this.whatsapp.numbers.includes(this.number)) {
					this.formatAndAdd(this.number);
					this.number = '';
				} else {
					PRINOR_TOASTS.general.info(this, { code: 'D-1' });
				}
			}
		},
		deleteNumber: function(number) {
			this.whatsapp.numbers = this.whatsapp.numbers.filter(value => { return value !== number; });
		},
		fileChange: function(event) {
			let that = this;
			const file = event.target.files[0];

			if (typeof file != 'undefined') {
				if (file.type === 'text/csv') {
					let reader = new FileReader();
					reader.readAsText(file, 'UTF-8');
					reader.onload = evt => {
						let test = window.Papa.parse(evt.target.result);

						if (test.data.length > 0) {
							that.csvData = test.data;
							this.columnSelectData = [];
							test.data[0].forEach(name => {
								this.columnSelectData.push(name);
							});
						}

						this.$modal.show('csv-config');
					};
				} else if (file.type === 'text/plain') {
					let reader = new FileReader();

					reader.onload = function() {
						let lines = this.result.split('\n');
						for (let line = 0; line < lines.length; line++) {
							if (that.validateNumber(lines[line])) {
								that.formatAndAdd(lines[line]);
							}
						}
					};

					reader.readAsText(file);
				} else {
					PRINOR_TOASTS.general.error(this, { code: 'D-2' });
				}
			}
		},
		addNumbersCsv: function() {
			let col = this.columnSelect;
			if (col > -1) {
				if (this.csvData.length > 0) {
					this.csvData.forEach(row => {
						if (this.validateNumber(row[col])) {
							this.formatAndAdd(row[col]);
						}
					});
					this.$modal.hide('csv-config');
				}
			}
		},
		formatAndAdd: function(val) {
			let phoneNumber = parsePhoneNumber(val, 'DE');
			if (phoneNumber) {
				let intlNumber = phoneNumber.formatInternational();
				this.whatsapp.numbers.push(intlNumber);
			}
		},
		validateNumber: function(number) {
			return !Number.isNaN(number);
		},
		hideModal: function() {
			this.$modal.hide('csv-config');
		},
		onSend: function(bvModalEvt) {
			bvModalEvt.preventDefault();

			this.$v.$touch();
			if (!this.$v.whatsapp.$invalid) {
				this.checkPrice().then(data => {
					Swal.fire({
						title: this.$t('VIEWS.Dashboard.Share.Whatsapp.confirmTitle'),
						text: this.$t('VIEWS.Dashboard.Share.Whatsapp.confirmText', { amount: this.whatsapp.numbers.length, price: data.data.price, unit: data.data.unit, total: data.data.total }),
						icon: 'question',
						confirmButtonText: this.$t('GENERAL.Buttons.send'),
						showCancelButton: true,
						cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
						focusConfirm: false,
						customClass: {
							confirmButton: 'btn btn-success',
							cancelButton: 'btn btn-secondary',
						},
						heightAuto: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						allowEnterKey: false,
					}).then(result => {
						if (result.isConfirmed) {
							this.sendSms().then(data => {
								if (data.success === true) {
									this.$emit('closeSMS');
									if (data.data.length > 0) {
										Swal.fire({
											title: this.$t('VIEWS.Dashboard.Share.Whatsapp.notSendTitle'),
											text: this.$t('VIEWS.Dashboard.Share.notSend') + ' ' + data.data.join(', '),
											icon: 'warning',
											confirmButtonText: this.$t('GENERAL.Buttons.close'),
											showCancelButton: false,
											customClass: {
												confirmButton: 'btn btn-success',
												cancelButton: 'btn btn-secondary',
											},
											heightAuto: false,
											allowOutsideClick: true,
											allowEscapeKey: true,
											allowEnterKey: false,
										});
									} else {
										PRINOR_TOASTS.general.confirmation(this, this.$t('VIEWS.Dashboard.Share.Whatsapp.success'));
									}
								} else {
									PRINOR_TOASTS.general.error(this, data.error);
								}
							});
						}
					});
				});
			} else {
				if (this.$v.whatsapp.numbers.$invalid) {
					PRINOR_TOASTS.general.error(this, { code: 'D-2' });
				}
				if (this.$v.whatsapp.template.$invalid) {
					PRINOR_TOASTS.general.error(this, { code: 'D-2' });
				}
			}
		},
		checkPrice: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('survey/share/whatsapp/' + this.survey.id, this.whatsapp).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === false) {
							PRINOR_TOASTS.general.error(this, data.data.error);
						} else {
							resolve(data.data);
						}
					}
				});
			});
		},
		sendSms: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('survey/share/whatsapp/' + this.survey.id, this.whatsapp).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === false) {
							PRINOR_TOASTS.general.error(this, data.data.error);
						} else {
							resolve(data.data);
						}
					}
				});
			});
		},
	},
};
</script>

<style scoped></style>
