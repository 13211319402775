<template>
	<div>
		<!--begin::Dashboard-->
		<div class="row">
			<div class="col-xxl-6">
				<ActiveSurveys :table-data="active" :share-permissions="sharePermissions"></ActiveSurveys>
			</div>
			<div class="col-xxl-6">
				<CompletedSurveys :table-data="completed"></CompletedSurveys>
			</div>
			<div class="col-xxl-4">
				<DraftSurveys :table-data="drafts"></DraftSurveys>
			</div>
		</div>
		<!--end::Dashboard-->
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ActiveSurveys from '@/view/component/dashboard/ActiveSurveys';
import ApiService from '@/core/services/api.service';
import CompletedSurveys from '@/view/component/dashboard/CompletedSurveys';
import DraftSurveys from '@/view/component/dashboard/DraftSurveys';

export default {
	name: 'dashboard',
	components: {
		DraftSurveys,
		CompletedSurveys,
		ActiveSurveys,
	},
	data() {
		return {
			active: [],
			completed: [],
			drafts: [],
			sharePermissions: {
				'sms': false,
				'whatsapp': false,
			}
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Dashboard' }]);
		this.$on('update-dashboard', this.triggerUpdate);



		this.get().then(data => {
			this.active = data.data.active;
			this.completed = data.data.completed;
			this.drafts = data.data.drafts;
			this.sharePermissions = data.data.share;
		});
	},
	// sockets: {
	// 	connect: function () {
	// 		console.log('socket connected')
	// 	},
	// },
	methods: {
		get() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('dashboard/get')
					.then(({ data }) => {
						resolve(data);
					});
					// .catch(({ response }) => {
					// 	console.error('Dashboard', response);
					// });
			});
		},
		triggerUpdate() {
			this.get().then(data => {
				this.active = data.data.active;
				this.completed = data.data.completed;
				this.drafts = data.data.drafts;
				this.sharePermissions = data.data.share;
			});
		},
	},
};
</script>
