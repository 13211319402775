<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Dashboard.Completed.title') }}</span>
				<span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('VIEWS.Dashboard.Completed.description') }}</span>
			</h3>
			<div class="card-toolbar align-items-start">
				<i class="fas fa-square"></i>
			</div>
		</div>
		<div class="card-body py-3">
			<div class="col-12 prinor-table">
				<b-table ref="textTable" :data="tableData" :paginated="true" :per-page="5" default-sort="completed_at_unix" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.name }}</span>
						</template>
					</b-table-column>
					<b-table-column field="subscriber_amount" :label="$t('GENERAL.General.subscriber')" v-slot="props" :sortable="true" cell-class="text-valign">
						<template>
							<span>{{ props.row.subscriber_amount }}</span>
						</template>
					</b-table-column>
					<b-table-column field="completed_at_unix" :label="$t('VIEWS.Dashboard.Completed.enddate')" v-slot="props" :sortable="true" cell-class="text-valign">
						<template>
							<span>{{ props.row.completed_at }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="text-right" header-class="text-right">
						<template>
							<router-link :to="{ name: 'report', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
								<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.report') ? 'disabled' : ''" :title="$t('VIEWS.Dashboard.Active.reportTitle')">
									<i class="fas fa-file-medical-alt text-primary"></i>
								</a>
							</router-link>
							<a href="" v-on:click.prevent="onReset(props.row.id)" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.report') ? 'disabled' : ''" :title="$t('VIEWS.Dashboard.Completed.resetTooltip')">
								<i class="fas fa-undo text-primary"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
	</div>
</template>

<script>
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import Swal from "sweetalert2";
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import ApiService from "@/core/services/api.service";

export default {
	name: 'CompletedSurveys',
	props: {
		tableData: Array,
	},
	data() {
		return {
			name: '',
		};
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		onReset: function(id) {
			Swal.fire({
				title: this.$t('VIEWS.Dashboard.Completed.resetTitle'),
				text: this.$t('VIEWS.Dashboard.Completed.resetText'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.Buttons.reset'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-warning',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.processReset(id).then();
				}
			});
		},
		processReset: function(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('survey/reset/' + id, { id: id })
					.then(({ data }) => {
						if (data.success === true) {
							PRINOR_TOASTS.general.confirmation(this, this.$t('VIEWS.Dashboard.Completed.resetSuccess'));
							this.$parent.$emit('update-dashboard');
						} else {
							PRINOR_TOASTS.general.error(this);
						}

						resolve(data);
					})
					.catch(() => {
						PRINOR_TOASTS.general.error(this, this.$t('VIEWS.Dashboard.Active.stop.error'));
					});
			});
		},
	},
};
</script>

<style scoped></style>
