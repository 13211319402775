<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Dashboard.Active.title') }}</span>
					<span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('VIEWS.Dashboard.Active.description') }}</span>
				</h3>
				<div class="card-toolbar align-items-start">
					<i class="fas fa-circle fa-pulsate text-danger"></i>
				</div>
			</div>
			<div class="card-body py-3">
				<div class="col-12 prinor-table">
					<b-table ref="textTable" :data="tableData" :paginated="true" :per-page="5" default-sort="started_at" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
						<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
							<template>
								<span class="truncate">{{ props.row.name }}</span>
							</template>
						</b-table-column>
						<b-table-column field="subscriber_amount" :label="$t('GENERAL.General.subscriber')" v-slot="props" :sortable="true" cell-class="text-valign">
							<template>
								<span>{{ props.row.subscriber_amount }}</span>
							</template>
						</b-table-column>
						<b-table-column field="started_at" :label="$t('VIEWS.Dashboard.Active.startdate')" v-slot="props" :sortable="true" cell-class="text-valign">
							<template>
								<span>{{ new Date(props.row.started_at).toLocaleString() }}</span>
							</template>
						</b-table-column>
						<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="text-right d-grid" header-class="text-right">
							<template>
								<b-dropdown size="xs" variant="link" toggle-class="btn btn-icon btn-light btn-dropdown btn-sm mr-1 text-decoration-none" no-caret right no-flip :title="$t('VIEWS.Dashboard.Active.share.shareTitle')">
									<template v-slot:button-content>
										<i class="fas fa-share-alt text-primary"></i>
									</template>
									<b-dropdown-text tag="div">
										<ul class="navi navi-hover py-4">
											<li class="navi-item">
												<a href="" v-on:click.prevent="showLinkModal(props.row.id)" class="navi-link">
													<span class="symbol symbol-20 mr-3">
														<i class="fas fa-link text-primary"></i>
													</span>
													<span class="navi-text">{{ $t('VIEWS.Dashboard.Active.share.link') }}</span>
												</a>
											</li>
											<li class="navi-item">
												<a href="" v-on:click.prevent="showQRModal(props.row.id)" class="navi-link">
													<span class="symbol symbol-20 mr-3">
														<i class="fas fa-qrcode text-primary"></i>
													</span>
													<span class="navi-text">{{ $t('VIEWS.Dashboard.Active.share.qr') }}</span>
												</a>
											</li>
											<li class="navi-item">
												<router-link :to="{ name: 'survey-mail', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
													<a :href="href" @click="navigate" class="navi-link" :class="!hasPermission('action.survey.share.mail') ? 'disabled' : ''">
														<span class="symbol symbol-20 mr-3">
															<i class="fas fa-envelope text-primary"></i>
														</span>
														<span class="navi-text">{{ $t('VIEWS.Dashboard.Active.share.mail') }}</span>
													</a>
												</router-link>
											</li>
											<li class="navi-item" v-if="sharePermissions.sms === true">
												<a href="" v-on:click.prevent="showSmsModal(props.row.id)" class="navi-link" :class="!hasPermission('action.survey.share.sms') ? 'disabled' : ''">
													<span class="symbol symbol-20 mr-3">
														<i class="fas fa-sms text-primary"></i>
													</span>
													<span class="navi-text">{{ $t('VIEWS.Dashboard.Active.share.sms') }}</span>
												</a>
											</li>
											<li class="navi-item" v-if="sharePermissions.whatsapp === true">
												<a href="" v-on:click.prevent="showWhatsappModal(props.row.id)" class="navi-link" :class="!hasPermission('action.survey.share.whatsapp') ? 'disabled' : ''">
													<span class="symbol symbol-20 mr-3">
														<i class="fab fa-whatsapp text-primary"></i>
													</span>
													<span class="navi-text">{{ $t('VIEWS.Dashboard.Active.share.whatsapp') }}</span>
												</a>
											</li>
										</ul>
									</b-dropdown-text>
								</b-dropdown>
								<router-link :to="{ name: 'report', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
									<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.report') ? 'disabled' : ''" :title="$t('VIEWS.Dashboard.Active.reportTitle')">
										<i class="fas fa-file-medical-alt text-primary"></i>
									</a>
								</router-link>
								<a href="#" v-on:click.prevent="surveyStop(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm text-hover-danger mx-1" :class="(!hasPermission('action.survey.stop')) || props.row.urlReading === true ? 'disabled' : ''" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
									<i class="fas fa-square text-primary"></i>
								</a>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
		<b-modal id="share-link" :title="$t('VIEWS.Dashboard.Active.share.link')" ok-only>
			<b-input-group>
				<template #append>
					<b-input-group-text v-on:click.prevent="copyLink" class="cursor-pointer">
						<i class="far fa-copy"></i>
					</b-input-group-text>
				</template>
				<b-input type="text" readonly :value="getSurveyUrl + selectedSurvey.url" ref="copyInput"></b-input>
			</b-input-group>
		</b-modal>
		<b-modal id="share-qr" :title="$t('VIEWS.Dashboard.Active.share.qr')" @shown="buildQrCode" @ok="downloadQrCode" :cancel-title="$t('GENERAL.Buttons.cancel')" :ok-title="$t('GENERAL.Buttons.download')">
			<b-input-group>
				<template #prepend>
					<b-input-group-text v-on:click.prevent="copyLink" class="cursor-pointer">
						{{ $t('VIEWS.Dashboard.Active.share.Qr.style') }}
					</b-input-group-text>
				</template>
				<b-select value="square" @change="updateQrCode">
					<b-select-option value="dots">{{ $t('VIEWS.Dashboard.Active.share.Qr.dot') }}</b-select-option>
					<b-select-option value="square">{{ $t('VIEWS.Dashboard.Active.share.Qr.square') }}</b-select-option>
					<b-select-option value="rounded">{{ $t('VIEWS.Dashboard.Active.share.Qr.rounded') }}</b-select-option>
				</b-select>
			</b-input-group>
			<div ref="qrCodeCanvas" class="qr-canvas text-center mt-4"></div>
		</b-modal>
		<b-modal
			id="share-sms"
			content-class="modal-border"
			size="xl"
			:title="$t('VIEWS.Dashboard.Share.Sms.title') + ' ' + selectedSurvey.name"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Buttons.send')"
			v-on:ok="sendSms"
			scrollable
		>
			<ShareSMS :survey="selectedSurvey" ref="shareSms" @closeSMS="closeSMS"></ShareSMS>
		</b-modal>
		<b-modal
			id="share-whatsapp"
			content-class="modal-border"
			size="xl"
			:title="$t('VIEWS.Dashboard.Share.Whatsapp.title') + ' ' + selectedSurvey.name"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Buttons.send')"
			v-on:ok="sendWhatsapp"
			scrollable
		>
			<ShareWhatsapp :survey="selectedSurvey" ref="shareWhatsapp" @closeSMS="closeWhatsapp"></ShareWhatsapp>
		</b-modal>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import ShareSMS from '@/view/component/dashboard/share/ShareSMS';
import ShareWhatsapp from '@/view/component/dashboard/share/ShareWhatsapp';
import QRCodeStyling from 'qr-code-styling';
import {mapGetters} from "vuex";

export default {
	name: 'ActiveSurveys',
	components: { ShareWhatsapp, ShareSMS },
	props: {
		tableData: Array,
		sharePermissions: Object,
	},
	data: function() {
		return {
			selectedSurvey: {
				id: '',
				url: '',
				name: '',
			},
			qrCode: null,
		};
	},
	computed: {
		...mapGetters(['getSurveyUrl', 'currentUser']),
	},
	methods: {
		getSurveyCompanyUrl: function(survey) {
			if (survey.survey_urls != null && survey.survey_urls.length > 0) {
				if (survey.survey_urls.length === 1) {
					return survey.survey_urls[0].url;
				}
				else {
					let filtered = survey.survey_urls.filter(value => { return value.company_id === this.currentUser.company_id; });
					if (filtered != null && filtered.length > 0) {
						return filtered[0].url;
					}
					else {
						filtered = survey.survey_urls.filter(value => { return value.company_id == null; });
						if (filtered != null && filtered.length > 0) {
							return filtered[0].url;
						}
					}
				}
			}

			return '';
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		surveyStop: function(id, name) {
			// let that = this;
			Swal.fire({
				title: this.$t('VIEWS.Dashboard.Active.stop.title'),
				text: this.$t('VIEWS.Dashboard.Active.stop.text', { name: name }),
				icon: 'question',
				confirmButtonText: this.$t('VIEWS.Dashboard.Active.stop.stop'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.update(id);
				}
			});
		},
		update: function(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('survey/stop/' + id, { id: id })
					.then(({ data }) => {
						if (data.success === true) {
							PRINOR_TOASTS.general.confirmation(this, this.$t('VIEWS.Dashboard.Active.stop.success'));
							this.$parent.$emit('update-dashboard');
						} else {
							PRINOR_TOASTS.general.error(this, this.$t('VIEWS.Dashboard.Active.stop.error'), data.error.code);
						}

						resolve(data);
					})
					.catch(() => {
						PRINOR_TOASTS.general.error(this, this.$t('VIEWS.Dashboard.Active.stop.error'));
						// console.error('Survey Stop', response);
					});
			});
		},
		showLinkModal: function(id) {
			let filtered = this.tableData.filter(value => {
				return id === value.id;
			});
			if (filtered.length > 0) {
				this.selectedSurvey.url = this.getSurveyCompanyUrl(filtered[0]);
				this.selectedSurvey.id = filtered[0].id;
				this.selectedSurvey.name = filtered[0].name;
			}

			this.$bvModal.show('share-link');
		},
		showQRModal: function(id) {
			let filtered = this.tableData.filter(value => {
				return id === value.id;
			});
			if (filtered.length > 0) {
				this.selectedSurvey.url = this.getSurveyCompanyUrl(filtered[0]);
				this.selectedSurvey.id = filtered[0].id;
				this.selectedSurvey.name = filtered[0].name;
			}

			this.$bvModal.show('share-qr');
		},
		buildQrCode: function() {
			this.qrCode = new QRCodeStyling({
				width: 300,
				height: 300,
				data: this.getSurveyUrl + this.selectedSurvey.url,
				dotsOptions: {
					color: '#000',
					type: 'square',
				},
				backgroundOptions: {
					color: '#fff',
				},
			});

			this.qrCode.append(this.$refs.qrCodeCanvas);
		},
		updateQrCode: function(val) {
			let squareOptions = val;
			let dotOptions = val;
			if (val === 'rounded' || val === 'dots') {
				squareOptions = 'extra-rounded';
			}

			if (dotOptions === 'dots') {
				dotOptions = 'dot';
			} else {
				dotOptions = 'square';
			}

			this.qrCode.update({
				dotsOptions: {
					type: val,
				},
				cornersSquareOptions: {
					type: squareOptions,
				},
				cornersDotOptions: {
					type: dotOptions,
				},
			});
		},
		downloadQrCode: function() {
			this.qrCode.download({
				name: this.selectedSurvey.name + '_qr',
				extension: 'png',
			});
		},
		showSmsModal: function(id) {
			let filtered = this.tableData.filter(value => {
				return id === value.id;
			});
			if (filtered.length > 0) {
				this.selectedSurvey.url = this.getSurveyCompanyUrl(filtered[0]);
				this.selectedSurvey.id = filtered[0].id;
				this.selectedSurvey.name = filtered[0].name;
			}

			this.$bvModal.show('share-sms');
		},
		showWhatsappModal: function(id) {
			let filtered = this.tableData.filter(value => {
				return id === value.id;
			});
			if (filtered.length > 0) {
				this.selectedSurvey.url = this.getSurveyCompanyUrl(filtered[0]);
				this.selectedSurvey.id = filtered[0].id;
				this.selectedSurvey.name = filtered[0].name;
			}

			this.$bvModal.show('share-whatsapp');
		},
		copyLink: function() {
			let input = this.$refs.copyInput;
			input.select();
			input.setSelectionRange(0, 99999);
			document.execCommand('copy');
		},
		sendSms: function(bvModalEvt) {
			this.$refs.shareSms.onSend(bvModalEvt);
		},
		sendWhatsapp: function(bvModalEvt) {
			this.$refs.shareWhatsapp.onSend(bvModalEvt);
		},
		closeSMS: function() {
			this.$bvModal.hide('share-sms');
		},
		closeWhatsapp: function() {
			this.$bvModal.hide('share-whatsapp');
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
.dropdown-menu.show {
	display: block !important;
	white-space: nowrap;
}
</style>
