<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Dashboard.Draft.title') }}</span>
				<span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('VIEWS.Dashboard.Draft.description') }}</span>
			</h3>
			<div class="card-toolbar align-items-start">
				<i class="fas fa-square"></i>
			</div>
		</div>
		<div class="card-body py-3">
			<div class="col-12 prinor-table">
				<b-table ref="textTable" :data="tableData" :paginated="true" :per-page="5" default-sort="name" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.name }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="text-right" header-class="text-right">
						<template>
							<router-link :to="{ name: 'survey-edit', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
								<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.edit') ? 'disabled' : ''" :title="$t('MENU.Survey.editSurvey')">
									<i class="fas fa-edit text-primary"></i>
								</a>
							</router-link>
							<a href="#" v-on:click.prevent="showStartDialog(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.start') ? 'disabled' : ''" :title="$t('VIEWS.Dashboard.Draft.start.title')">
								<i class="fas fa-play text-primary"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
		<b-modal ref="start-modal" :title="$t('VIEWS.Dashboard.Draft.start.title') + ': ' + start.name" :cancel-title="$t('GENERAL.Buttons.cancel')" :ok-title="$t('GENERAL.Buttons.start')" v-on:ok="startSurvey" :on-cancel="resetDialog">
			<form v-on:submit="startSurvey">
				<p>{{ $t('VIEWS.Dashboard.Draft.start.description') }}</p>
				<b-input-group :prepend="getSurveyUrl" class="mt-3">
					<b-form-input name="url" v-model="start.url"></b-form-input>
				</b-input-group>
			</form>
		</b-modal>
	</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import {mapGetters} from "vuex";

export default {
	name: 'DraftSurveys',
	props: {
		tableData: Array,
	},
	computed: {
		...mapGetters(['getSurveyUrl']),
	},
	data() {
		return {
			name: '',
			start: {
				id: '',
				name: '',
				url: '',
			},
		};
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		showStartDialog: function(id, name) {
			this.start.id = id;
			this.start.name = name;

			let survey = this.tableData.filter(value => { return value.id === id; });
			if (survey.length === 1) {
				survey[0].survey_urls.forEach(url => {
					if (url.company_id == null || url.company_id === '') {
						this.start.url = url.url;
					}
				});
			}

			this.$refs['start-modal'].show();
		},
		resetDialog: function() {
			this.start.id = '';
			this.start.url = '';
			this.start.name = '';
		},
		startSurvey: function() {
			this.update().then(() => {
				this.resetDialog();
			});
		},
		update: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('survey/start/' + this.start.id, this.start).then(data => {
					this.isUpdating = false;
					if (data.data.success === true) {
						PRINOR_TOASTS.save.confirmation(this);
						this.$parent.$emit('update-dashboard');
					} else {
						PRINOR_TOASTS.general.error(this, data.data.error);
					}

					resolve(data);
				});
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
